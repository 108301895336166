import * as React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Sterea Medicina e Viaggi" />
      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
